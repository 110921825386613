
  .cn-forcastList-display.dark {
    height: calc(100% - 136px - 5px);
    width: calc(100% - 5px - 5px - 5px - 5px);
    padding: 5px 5px;
    margin: 0px 5px;
    border-radius: 10px;
    align-items: center;
    background-color: #2e2e2e;
  }
  
  .cn-forcastList-display.light {
    height: calc(100% - 136px - 5px);
    width: calc(100% - 5px - 5px - 5px - 5px);
    padding: 5px 5px;
    margin: 0px 5px;
    border-radius: 10px;
    align-items: center;
    background-color: white;
  }


  /* :root {
    --dark: #4c4b4d;
    --light: #white;
  } */